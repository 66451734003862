const axios = require('axios');
import VueCookies from 'vue-cookies'

class APIRepository {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL,
    });
  }

  /**
   * Get Buildings
   * @param {number} pro_id - The property ID
   * @returns {Promise} - The response (axios promise)
   */
  async Login(email, password) {
    return await this.client.post('/auth/login', { email, password});
  }
    
    async getMe() {
        console.log(VueCookies.get('jwt'));
        this.client.defaults.headers.common['Authorization'] = `Bearer ${VueCookies.get('jwt')}`;
        console.log(this.client.defaults.headers.common['Authorization']);
    return await this.client.get('/auth/me');
    }

  /**
   * Get Cities
   * @param {number} state_id - The state ID
   * @returns {Promise} - The response (axios promise)
   */
    async updateProfile(email, phone, firstname, lastname) {
        return await this.client.put('/profile/', {
            email,
            phone,
            firstname,
            lastname
        });
    }

    async updateWLAN(ssid, password) {
        return await this.client.put('/wlan', {
            ssid,
            password
        } );
    }

    async updateLAN(lanPorts) {
        return await this.client.put('/lan', lanPorts );
    }

    async getBillingPortal() {
        return await this.client.get('/billing/billingPortal');
    }

    async getClients() {
        return await this.client.get('/clients');
    }
    async logout() {
        return await this.client.delete('/auth/logout');
    }
}

// Create the repository object with the desired base URL
const repository = new APIRepository('https://prod-api-gateway-v2-dot-ruckus-vsz-257719.uc.r.appspot.com/dashboard/');

export default repository;
