<template>
    <v-container class="mt-5">
        <v-row align="center" justify="center">

            <v-progress-circular indeterminate size="100" v-if="loading"/>

        </v-row>
        <v-row align="center" justify="center">
            <p> Loading ... </p>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :multi-line="snackbar.multiLine" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.text }}
        </v-snackbar>

    </v-container>
</template>

<script>
    import {dashboard} from '../../api/api.js'

    import {sb_succ,
        sb_error
    } from '../../api/helpers.js'

    export default ({

        data() {
            return {
                snackbar: {
                    multiLine: false,
                    visible: false,
                    text: ``,
                    color: '',
                    timeout: 3000,
            }
            }
        },
        methods: {
            getParameterByName(name, url = window.location.href) {
                name = name.replace(/[[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }

        },
        mounted() {
            this.loading = true;
            var hash = this.getParameterByName('auth')

            if (!hash) {
                this.snackbar = sb_error("There is no auth key provided. Redirecting to login...")
                setTimeout(() => {
                    this.$router.push('/login')
                }, 3000)
                return
            }

            dashboard.validate_link(hash).then(
                (res) => {
                    this.snackbar = sb_succ("Redirecting you to Dashboard...")
                    this.$cookies.set("aerwave_data", {
                        jwt: res.data.jwt
                    });
                    localStorage.setItem('jwt', res.data.jwt);
                    this.$router.push("/");
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 3000)
                    return
                }
            ).catch(
                (error) => {
                    this.snackbar = sb_error(error.response.data.detail)
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 3000)
                }
            ).finally( () => (this.loading = false))


            console.log(hash)
        }
    })
</script>