<template>
<v-app>
    <v-main>
        <router-view />
    </v-main>
</v-app>
</template>

<script>

export default {
    name: 'App',
    components: {
    },

    data: () => ({
        //
    }),
    mounted() {
        localStorage.clear();
        if (this.$cookies.isKey('aerwave')) {
            localStorage.setItem('jwt', this.$cookies.get('aerwave').jwt)
        }
        if (!this.$vuetify.breakpoint.mdAndDown) {
                this.$intercom.boot({hide_default_launcher: false});
            } else {
                this.$intercom.boot({hide_default_launcher: true});
            }
    }
};
</script>
