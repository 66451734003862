import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueIntercom from 'vue-intercom';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueSweetalert2);
Vue.use(require('vue-cookies'))

Vue.$cookies.config('7d', '', '', true)
Vue.config.productionTip = false

Vue.use(VueIntercom, {appId: 't2cc4rjj'});

new Vue({
    vuetify,
    router,
    render: h => h(App),

    data:()=>(
        {
            userId: 1,
            name: 'Dan Jensen',
            email: 'NOC@aerwave.io',
        }
    ),

    created() {
        this.$intercom.shutdown();
        this.$intercom.once('ready', this.bootIntercom);
    },

    watch: {
        email(email) {
            this.$intercom.update({ email });
        },
    },

    store,

    methods: {
        bootIntercom() {
            this.$intercom.boot({
                user_id: this.userId,
                name: this.name,
                email: this.email,
            });
            this.$intercom.show();
        },
    }
}).$mount('#app')
