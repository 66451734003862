import axiosBase from 'axios'

let baseURL = 'https://ruckus-vsz-257719.uc.r.appspot.com/api/v2/'
// let baseURL = 'http://127.0.0.1:9091/api/v2/'
// let baseURL = 'http://bs-local.com:9091/api/v2/'

// creating axios instance
const axios = axiosBase.create({
    baseURL
})

axios.interceptors.request.use(config => {
    handleRequestInterceptor(config)

    return config
})


function getAuth() {
    return {Authorization: "Bearer " + localStorage.getItem('jwt'),
            CusAuth: "Bearer " + localStorage.getItem('jwt')}
}

const portal_api = {
    get_subs: (pro_id) => axios({
        url: '/check-availability/subscription-plans',
        method: 'GET',
        params: {pro_id: pro_id},
    }),
}

const dashboard = {
    validate_session: () => axios({
        url: '/login/validate-session',
        method: 'GET',
        headers: getAuth()
    }),
    login: (login_schema) => axios({
        url: '/login/login-wlan',
        method: 'POST',
        data: login_schema
    }),
    logout: () => axios({
        url: '/login/logout',
        method: 'DELETE',
        headers: getAuth()
    }),

    overview: () => axios({
        url: '/dashboard/overview',
        method: 'GET',
        headers: getAuth()
    }),

    clients: () => axios({
        url: '/dashboard/clients',
        method: 'GET',
        headers: getAuth()
    }),

    activity: () => axios({
        url: '/dashboard/sessions',
        method: 'GET',
        params:{active_session: localStorage.getItem('jwt')},
        headers: getAuth()
    }),
    delete_activity: (ses_id) => axios({
        url: '/dashboard/sessions',
        method: 'DELETE',
        params:{ses_id: ses_id},
        headers: getAuth()
    }),

    cus_portal: () => axios({
        url: '/dashboard/stripe/cus-portal',
        method: 'PUT',
        headers: getAuth()
    }),

    create_mpw: (data) => axios({
        url: '/dashboard/mpw',
        method: 'POST',
        data: data,
        headers: getAuth()
    }),

    update_mpw: (data) => axios({
        url: '/dashboard/mpw',
        method: 'PUT',
        data: data,
        headers: getAuth()
    }),

    update_wlan: (data) => axios({
        url: '/dashboard/wlan',
        method: "PUT",
        data: data,
        params: {wlan_hash: data.wlan_hashed_id},
        headers: getAuth()
    }),
    update_lan: (data) => axios({
        url: '/dashboard/lan',
        method: "PUT",
        data: data,
        headers: getAuth()
    }),
    update_profile: (data) => axios({
        url: '/dashboard/user',
        method: "PUT",
        data: data,
        headers: getAuth()
    }),
    get_issues: () => axios({
        url: '/dashboard/user/health',
        method: 'GET',
        headers: getAuth()
    }),
    get_subscriptions: () => axios({
        url: '/dashboard/stripe/available-subscriptions',
        method: 'GET',
        headers: getAuth()
    }),
    update_subscription: (price_id) => axios({
        url: 'dashboard/stripe/subscription',
        method: "PUT",
        params: {price_id: price_id},
        headers: getAuth()
    }),
    reset_pw: (query) => axios( {
        url: '/reset-links/request',
        method: 'GET',
        params: {query: query}
    }),
    validate_link : (hash_id) => axios({
        url: '/reset-links/get_session',
        method: 'GET',
        params: {hash_id: hash_id}
    }),
    get_referrals : () => axios({
        url: 'dashboard/referrals/',
        method: 'GET',
        headers: getAuth()
    }),
    generate_promocode : (email) => axios({
        url: 'dashboard/referrals/generate',
        method: 'GET',
        params: {email_to: email},
        headers: getAuth()
    })
}



function handleRequestInterceptor(config) {
    try {
        const sid = localStorage.getItem('sid')
        if (config.params && sid) {
            config.params.sid = sid
        } else if (sid) {
            config.params = {
                sid
            }
        }
    } catch (err) {
        console.log('err', err)
    }

}

export {
    dashboard,
    portal_api,
    baseURL,
}