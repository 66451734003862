<template>

    <v-container class="mt-15 mb-10" v-if="loading">
            <v-container class="mt-15 mb-10">
                <v-container class="mt-15 mb-15">

                    <loading-wifi-animation/>
                </v-container>
            </v-container>
        </v-container>
</template>

<script>
import {
    dashboard
} from '../../api/api.js'
import LoadingWifiAnimation from '../LoadingWifiAnimation.vue'
export default ({
    components: {
       'loading-wifi-animation': LoadingWifiAnimation
    },
    data() {
        return {loading: true}
    },
    mounted() {

        dashboard.cus_portal().then(
            (res) => {
                window.location.href = res.data
            }
        ).catch(() => (this.$router.push('/'))).finally(() => {
            this.loading = false
        })
    }
})
</script>
