<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card min-height="300px">
          <v-card-title class="headline">Wlan Settings</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="saveUser">
              <v-text-field
                v-model="ssid"
                label="Network Name"
                :error-messages="ssidError"
                required
              ></v-text-field>

              <v-text-field
                v-model="passphrase"
                label="Network Password"
                :error-messages="passwordError"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              color="primary"
              @click="saveUser"
              :disabled="
                this.ssid === '' ||
                this.passphrase === '' ||
                !isValidSsid(this.ssid) ||
                !isValidPassword(this.passphrase)
              "
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
  computed: {
    ssid: {
      get() {
        return this.$store.state.user.wlan.ssid;
      },
      set(value) {
        this.$store.commit("SET_SSID_DATA", value);
      },
    },
    passphrase: {
      get() {
        return this.$store.state.user.wlan.password;
      },
      set(value) {
        this.$store.commit("SET_PASSWORD_DATA", value);
      },
    },

    ssidError() {
      return this.isValidSsid(this.ssid) ? [] : ["Please enter a valid SSID"];
    },
    passwordError() {
      return this.isValidPassword(this.passphrase)
        ? []
        : ["Please enter a valid password"];
    },
  },
  methods: {
    saveUser() {
      // show Swal confirmation dialog
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#f44336",

        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Saving your changes...",
            text: "Please wait for a few moments...",
            icon: "info",
            showConfirmButton: false,
            showCancelButton: false,
          });
          this.$store.dispatch("updateWLAN").then(() => {
            Swal.fire("Saved!", "Your profile has been saved.", "success");
          });
        } else {
          Swal.fire("Cancelled", "Synchronizing data...");
          this.$store.dispatch("getMe");
        }
      });
    },
    isValidSsid(name) {
      if (!name) {
        return false;
      }
      if (name.length < 3) {
        return false;
      }
      if (name.length > 32) {
        return false;
      }
      if (!name.match(/^[ -~]+$/)) {
        return false;
      }
      return true;
    },
    isValidPassword(value) {
      if (!value) {
        return "Passphrase is required";
      }
      if (value.length < 8) {
        return "Passphrase must be at least 8 characters long";
      }
      if (value.length > 63) {
        return "Passphrase must be less than 63 characters long";
      }
      if (!value.match(/^[ -~]+$/)) {
        return "Passphrase must contain only printable ASCII characters";
      }
      if (value.match(/[@?\\"'\\]/)) {
        return "Passphrase must not contain @ ?\" ' \\ characters";
      }
      return true;
    },
  },
};
</script>
