<template>
  <v-card>
    <v-card-title class="text-h5">Clients</v-card-title>
    <v-data-table
      v-if="$store.state.clients"
      :headers="headers"
      :items="$store.state.clients"
      :items-per-page="5"
      class="elevation-1"
      :search="search"
      ><template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
    <v-progress-linear v-else indeterminate color="deep-purple accent-4" />
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Mac Address",
          align: "start",
          filterable: false,
          value: "mac",
        },
        { text: "Host Name", value: "hostname" },
        { text: "Ip Address", value: "ip" },
        { text: "OS Type", value: "osType" },
        { text: "Device Type", value: "deviceType" },
        { text: "SSID", value: "ssid" },
        { text: "Traffic Upload", value: "uplink" },
        { text: "Traffic Download", value: "downlink" },
        { text: "Traffic Total", value: "traffic" },
      ],
    };
  },
};
</script>
