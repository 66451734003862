import Vue from 'vue'
import Vuex from 'vuex'
// customer module
import repository from '../api/client'
import Swal from 'sweetalert2'
import VueCookies from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeMenu: 'overview',
    email: '',
    password: '',
    jwt: '',
    billingPortal: '',
    user: null,
    clients: null,
  },
  getters: {
  },
  mutations: {
    SET_EMAIL(state, email) {
      state.email = email
    },
    SET_PASSWORD(state, password) {
      state.password = password
    },
    SET_EMAIL_DATA(state, emailData) {
      state.user.email = emailData
    },
    SET_PHONE_DATA(state, phoneData) {
      state.user.phone = phoneData
    },
    SET_FIRSTNAME_DATA(state, firstNameData) {
      state.user.firstname = firstNameData
    },
    SET_LASTNAME_DATA(state, lastNameData) {
      state.user.lastname = lastNameData
    },
    SET_JWT(state, jwt) {
      state.jwt = jwt
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
    },
    SET_ACTIVE_MENU(state, menu) {
      state.activeMenu = menu
    },
    SET_SSID_DATA(state, ssidData) {
      state.user.wlan.ssid = ssidData
    },
    SET_PASSWORD_DATA(state, passwordData) {
      state.user.wlan.password = passwordData
    },
    SET_LAN_PORTS(state, lanPorts) {
      state.user.lanPorts = lanPorts
    },
    SET_BILLING_PORTAL(state, billingPortal) {
      state.billingPortal = billingPortal
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients
    }
  },
  actions: {
    async LoginUser({ state, commit }) {
      Swal.showLoading()
      const response = await repository.Login(state.email, state.password).then(
        (data) => {
          commit('SET_EMAIL', '')
          commit('SET_PASSWORD', '')
          commit('SET_JWT', data.data.token)
          Swal.close()
          localStorage.setItem('jwt', data.data.token)
          VueCookies.set('jwt', data.data.token)
        }
      ).catch(
        () => {
          console.log('error')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      )
      .finally(
        () => {
          Swal.hideLoading()
        }
      )
      console.log(response);
    },
    async getMe({ commit }) {
      Swal.showLoading()
      const response = await repository.getMe().then(
        (data) => {
          console.log(data)
          // format data.data.phone into 123-456-7890
          let phone = data.data.phone
          phone = phone.replace('+1', '')
          let phoneFormatted = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
          data.data.phone = phoneFormatted
          commit('SET_USER_DATA', data.data)
          Swal.close()
        }
      ).catch(
        () => {
          console.log('error')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Looks like you are not logged in!',
          })
          throw new Error('Something went wrong')
        }
      ).finally(
        () => {
          Swal.hideLoading()
        }
      )
      console.log(response);
    },
    async updateProfile({dispatch}) {
      await repository.updateProfile(this.state.user.email,
        this.state.user.phone,
        this.state.user.firstname,
        this.state.user.lastname).then(() => { 
          dispatch('getMe')
         }).catch(
          () => {
            console.log('error')
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to update profile!',
            })
            throw new Error('Something went wrong')
          }
        )
    },
    async updateWLAN({ dispatch }) {
      
      await repository.updateWLAN(this.state.user.wlan.ssid,
        this.state.user.wlan.password).then(() => { 
          dispatch('getMe')
         }).catch(
          () => {
            console.log('error')
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to update wlan!',
            })
            throw new Error('Something went wrong')
          }
        )
    },
    async updateLAN({dispatch}) {
      await repository.updateLAN(this.state.user.lanPorts).then(() => { 
          dispatch('getMe')
         }).catch(
          () => {
            console.log('error')
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to update LAN Settings!',
            })
            throw new Error('Something went wrong')
          }
        )
    },
    async getBillingPortal({state}) {
      if (state.user.type !== "OPTIN") {
        return
      }
      
      Swal.showLoading()

      await repository.getBillingPortal().then(
        (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Redirecting to billing portal!',
          }).then(() => {
            Swal.showLoading()
          })
          window.open(data.data.url)
        }
      ).catch(
        () => {
          console.log('error')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to get billing portal!',
          })
          throw new Error('Something went wrong')
        }
      ).finally(
        () => {
          Swal.hideLoading()
        }
      )
    },
    async getClients({ commit }) {
      await repository.getClients().then(
        (data) => {
          commit('SET_CLIENTS', data.data)
        }
      ).catch(
        () => {
          console.log('error')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to get clients!',
          })
          throw new Error('Something went wrong')
        }
      )
    },
    async Logout({ commit }) {
      commit('SET_JWT', '')
      commit('SET_USER_DATA', {})
      await repository.logout().then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Logged out!',
        })
        localStorage.removeItem('jwt')
        VueCookies.remove('jwt')
        window.location.href = '/'
      }).catch(() => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to logout!',
          })
          throw new Error('Something went wrong')
      })
      
    }
  },
  modules: {
  }
})
