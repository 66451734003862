<template>
  <section style="background-color: #5bccf6">
    <v-card color="transparent">
      <v-row justify="center" align="center" style="background-color: #5bccf6">
        <v-app
          :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }"
          :dark="darkTheme"
          id="inspire"
          style="background-color: #5bccf6"
        >
          <v-row class="mt-15">
            <v-row class="mt-15" justify="center" align="center">
              <v-col :cols="$vuetify.breakpoint.smAndUp ? 24 : 8">
                <v-card outlined color="transparent">
                  <v-layout wrap>
                    <v-flex sm12 md6 offset-md3>
                      <v-card elevation="4" light tag="section">
                        <v-card-title>
                          <v-layout align-center justify-space-between>
                            <v-flex>
                              <v-img
                                :alt="Aerwave"
                                class="ml-3"
                                contain
                                height="48px"
                                position="center"
                                src="../assets/img/logo-new.png"
                              ></v-img>
                            </v-flex>
                          </v-layout>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <p>Sign in with your email and network password:</p>
                          <v-form v-model="valid">
                            <v-text-field
                              outline
                              label="E-mail"
                              type="text"
                              :rules="emailRules"
                              v-model="email"
                            ></v-text-field>
                            <v-text-field
                              outline
                              :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showPassword ? 'text' : 'password'"
                              @click:append="showPassword = !showPassword"
                              hide-details
                              label="Password"
                              :rules="passwordRules"
                              v-model="password"
                            ></v-text-field>
                          </v-form>
                          <v-card-text class="mt-3">
                            <!-- <v-row justify="center" align="center">

                                                        <v-dialog v-model="dialog" width="500">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn color="info" flat x-small v-bind="attrs" v-on="on">
                                                                    <v-icon x-small left>mdi-alert</v-icon>
                                                                    Forgot password?
                                                                </v-btn>
                                                            </template>

                                                            <v-card>
                                                                <v-card-title class="text-h5 grey lighten-2">
                                                                    Password Reset
                                                                </v-card-title>

                                                                <v-card-text>
                                                                    <p class="text-wrap">
                                                                        In order to recieve link that would let you sign in to dashboard,
                                                                        please enter your email address.
                                                                    </p>
                                                                    <v-form v-model="valid2">
                                                                        <v-text-field outline label="E-mail" type="text" :rules="emailRules" v-model="email"></v-text-field>
                                                                    </v-form>
                                                                </v-card-text>

                                                                <v-divider></v-divider>

                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn :loading="reset_pw_loading" color="primary" :disabled="!valid2" text @click="resetPassword()">
                                                                        Proceed
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-row> -->
                          </v-card-text>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions
                          :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            color="info"
                            :loading="loading"
                            :disabled="!valid"
                            @click="login()"
                            :small="$vuetify.breakpoint.smAndUp"
                          >
                            <v-icon left>mdi-lock</v-icon>
                            Login
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                    <v-flex sm12 md6 offset-md3>
                      <v-layout align-center justify-space-between>
                        <p class="caption my-3">
                          <a href="https://aerwave.io/privacy"
                            >Privacy Policy</a
                          >
                          |
                          <a href="https://aerwave.io/terms-services"
                            >Terms of Service</a
                          >
                        </p>
                        <p class="caption my-3">
                          Powered by <a href="https://aerwave.io">Aerwave</a>
                        </p>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-app>
      </v-row>
    </v-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      valid2: false,
      showPassword: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be 8+ characters len.",
      ],
      loading: false,
    };
  },
  computed: {
    email: {
      get() {
        return this.$store.state.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      },
    },
    password: {
      get() {
        return this.$store.state.password;
      },
      set(value) {
        this.$store.commit("SET_PASSWORD", value);
      },
    },
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store
        .dispatch("LoginUser")
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/login");
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
