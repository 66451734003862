var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","fixed":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.setMenu('overview')}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Overview")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.setMenu('profile')}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-card-account-details")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profile")])],1)],1),(_vm.$store.state.user && _vm.$store.state.user.lanPorts)?_c('v-list-item',{on:{"click":function($event){return _vm.setMenu('lan_settings')}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-network")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Lan Settings")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.setMenu('networks')}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-signal")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Networks")])],1)],1),(_vm.$store.state.user.type === 'OPTIN')?_c('v-list-item',{on:{"click":function () {
            _vm.setMenu('billing');
            _vm.redirectToBilling();
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-credit-card-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Billing")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function () {
            _vm.setMenu('support');
            _vm.showIntercom();
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-comment-question")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Support")])],1)],1),_c('v-list-item',{on:{"click":function () {
            _vm.Logout();
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1),_c('v-app-bar',{attrs:{"app":"","fixed":"","dark":"","color":"primary"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}}),_c('v-toolbar-title',[_vm._v("Aerwave")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }