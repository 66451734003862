

const deepEqual = (x, y) => {
 
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const sb_succ = (msg, timeout=3000)=>{
    var snackbar = {
        visible: true,
        timeout: timeout,
        color: 'green',
        text: msg
    }
    return snackbar
}

const sb_error = (msg, timeout=3000)=>{
    var snackbar = {
        visible: true,
        timeout: timeout,
        color: "red",
        text: msg
    }
    return snackbar
}


const release_version = "Release 3.0.1"

export {deepEqual, sb_error, sb_succ, formatBytes, release_version}
