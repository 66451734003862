<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card min-height="300px">
          <v-card-title class="headline">LAN Settings</v-card-title>
          <v-card-text>
            <v-switch
              v-for="port in lanPorts"
              :key="port.name"
              v-model="port.enabled"
              :label="port.name"
              @change="updateLanPorts(port.name, port.enabled)"
            >
            </v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" color="primary" @click="saveUser">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
  computed: {
    lanPorts: {
      get() {
        return this.$store.state.user.lanPorts;
      },
      set(value) {
        this.$store.commit("SET_LAN_PORTS", value);
      },
    },
  },
  methods: {
    updateLanPorts(name, enabled) {
      const lanPorts = JSON.parse(JSON.stringify(this.lanPorts));
      lanPorts.forEach((port) => {
        if (port.name === name) {
          port.enabled = enabled;
        }
      });
      this.lanPorts = lanPorts;
    },
    saveUser() {
      // show Swal confirmation dialog
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#f44336",

        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Saving your changes...",
            text: "Please wait for a few moments...",
            icon: "info",
            showConfirmButton: false,
            showCancelButton: false,
          });
          this.$store.dispatch("updateLAN").then(() => {
            Swal.fire("Saved!", "Your profile has been saved.", "success");
          });
        } else {
          Swal.fire("Cancelled", "Synchronizing data...");
          this.$store.dispatch("getMe");
        }
      });
    },
  },
};
</script>
