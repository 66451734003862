<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card min-height="300px">
          <v-card-title class="headline">Profile</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="saveUser">
              <v-text-field
                v-model="firstname"
                label="First Name"
                :error-messages="firstNameError"
                required
              ></v-text-field>

              <v-text-field
                v-model="lastname"
                label="Last Name"
                :error-messages="lastNameError"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="Email"
                :error-messages="emailError"
                required
              ></v-text-field>

              <v-text-field
                v-model="phone"
                label="Phone"
                :error-messages="phoneError"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              color="primary"
              @click="saveUser"
              :disabled="
                !isValidEmail(this.email) ||
                !isValidPhone(this.phone) ||
                !isValidName(this.name) ||
                !isValidName(this.lastname) ||
                this.email === '' ||
                this.phone === '' ||
                this.firstname === '' ||
                this.lastname === ''
              "
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    firstname: {
      get() {
        return this.$store.state.user.firstname;
      },
      set(value) {
        this.$store.commit("SET_FIRSTNAME_DATA", value);
      },
    },
    lastname: {
      get() {
        return this.$store.state.user.lastname;
      },
      set(value) {
        this.$store.commit("SET_LASTNAME_DATA", value);
      },
    },
    email: {
      get() {
        return this.$store.state.user.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL_DATA", value);
      },
    },
    phone: {
      get() {
        return this.$store.state.user.phone;
      },
      set(value) {
        this.$store.commit("SET_PHONE_DATA", value);
      },
    },

    firstNameError() {
      return this.isValidName(this.name)
        ? []
        : ["Please enter a valid first name"];
    },
    lastNameError() {
      return this.isValidName(this.lastname)
        ? []
        : ["Please enter a valid last name"];
    },
    emailError() {
      return this.isValidEmail(this.email)
        ? []
        : ["Please enter a valid email address"];
    },
    phoneError() {
      return this.isValidPhone(this.phone)
        ? []
        : ["Please enter a valid phone number (123-456-7890)"];
    },
  },
  methods: {
    saveUser() {
      // show Swal confirmation dialog
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#f44336",

        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Saving your changes...",
            text: "Please wait for a few moments...",
            icon: "info",
            showConfirmButton: false,
            showCancelButton: false,
          });
          this.$store.dispatch("updateProfile").then(() => {
            Swal.fire("Saved!", "Your profile has been saved.", "success");
          });
        } else {
          Swal.fire("Cancelled", "Synchronizing data...");
          this.$store.dispatch("getMe");
        }
      });
    },
    isValidName(name) {
      const regex = /^[a-zA-Z ]+$/;
      return regex.test(name);
    },
    isValidEmail(email) {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    isValidPhone(phone) {
      //verify that number is in the format 123-456-7890
      const regex = /^\d{3}-\d{3}-\d{4}$/;
      return regex.test(phone);
    },
  },
};
</script>
