<template>
  <div>
    <v-navigation-drawer app fixed v-model="showMenu">
      <v-list dense>
        <v-list-item @click="setMenu('overview')">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="setMenu('profile')">
          <v-list-item-action>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="setMenu('lan_settings')"
          v-if="$store.state.user && $store.state.user.lanPorts"
        >
          <v-list-item-action>
            <v-icon>mdi-network</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Lan Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="setMenu('networks')">
          <v-list-item-action>
            <v-icon>mdi-signal</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Networks</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            () => {
              setMenu('billing');
              redirectToBilling();
            }
          "
          v-if="$store.state.user.type === 'OPTIN'"
        >
          <v-list-item-action>
            <v-icon>mdi-credit-card-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Billing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            () => {
              setMenu('support');
              showIntercom();
            }
          "
        >
          <v-list-item-action>
            <v-icon>mdi-comment-question</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="
            () => {
              Logout();
            }
          "
        >
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed dark color="primary">
      <v-app-bar-nav-icon @click.stop="toggleMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>Aerwave</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    showMenu: false,
  }),

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    setMenu(name) {
      this.$store.commit("SET_ACTIVE_MENU", name);
    },
    redirectToBilling() {
      this.$store.dispatch("getBillingPortal");
    },
    showIntercom() {
      this.$intercom.show();
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
