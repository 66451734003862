<template>
  <v-app>
    <TopToolbar></TopToolbar>
    <BottomNav></BottomNav>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <Overview v-if="$store.state.activeMenu === 'overview'" />
          <Profile v-if="$store.state.activeMenu === 'profile'" />
          <Wlan v-if="$store.state.activeMenu === 'networks'" />
          <LanSettings v-if="$store.state.activeMenu === 'lan_settings'" />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import TopToolbar from "@/components/Navigation/TopToolbar.vue";
import BottomNav from "@/components/Navigation/BottomNav.vue";
import Profile from "../components/forms/Profile.vue";
import Wlan from "../components/forms/Wlan.vue";
import LanSettings from "../components/forms/LanSettings.vue";
import Overview from "../components/forms/Overview.vue";
export default {
  components: {
    TopToolbar,
    BottomNav,
    Profile,
    Wlan,
    LanSettings,
    Overview,
  },
  created() {
    this.$store
      .dispatch("getMe")
      .then(() => {
        console.log(this.$store.state.user);
        this.$store.dispatch("getClients");
      })
      .catch(() => {
        this.$router.push("/login");
      });
  },
};
</script>
