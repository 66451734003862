import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import BillingPortal from '../components/bridges/BillingPortal.vue'
import ResetLink from '../components/bridges/LinkReset.vue'
import FreeDecember from '../components/bridges/FreeDecember.vue'
import UserProfile from '../components/forms/Profile.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/userprofile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/billing-portal',
    name: 'Billing - Portal',
    component: BillingPortal,
  },
  {
    path: '/reset',
    name: 'ResetBridge',
    component: ResetLink,
  },
  {
    path: '/free-december',
    name: 'FreeDecember',
    component: FreeDecember,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
