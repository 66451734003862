<template>
  <v-bottom-navigation app fixed grow v-model="activeItem" color="primary">
    <v-btn v-if="$store.state.user" @click="setMenu('overview')">
      <span>Overview</span>
      <v-icon
        :color="`${$store.state.activeMenu === 'overview' ? 'primary' : ''}`"
        >mdi-view-dashboard</v-icon
      >
    </v-btn>

    <v-btn @click="setMenu('profile')">
      <span>Profile</span>
      <v-icon
        :color="`${$store.state.activeMenu === 'profile' ? 'primary' : ''}`"
        >mdi-card-account-details
      </v-icon>
    </v-btn>

    <v-btn
      v-if="$store.state.user && $store.state.user.lanPorts"
      @click="setMenu('lan_settings')"
    >
      <span>LAN Settings</span>
      <v-icon
        :color="`${
          $store.state.activeMenu === 'lan_settings' ? 'primary' : ''
        }`"
        >mdi-network</v-icon
      >
    </v-btn>
    <v-btn @click="setMenu('networks')">
      <span>Networks</span>
      <v-icon
        :color="`${$store.state.activeMenu === 'networks' ? 'primary' : ''}`"
        >mdi-signal</v-icon
      >
    </v-btn>
    <v-btn
      @click="
        () => {
          setMenu('billing');
          redirectToBilling();
        }
      "
      v-if="$store.state.user.type === 'OPTIN'"
    >
      <span>Billinng</span>
      <v-icon
        :color="`${$store.state.activeMenu === 'billing' ? 'primary' : ''}`"
        >mdi-credit-card-edit</v-icon
      >
    </v-btn>
    <v-btn @click="showIntercom">
      <span>Support</span>
      <v-icon
        :color="`${$store.state.activeMenu === 'support' ? 'primary' : ''}`"
        >mdi-comment-question</v-icon
      >
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    activeItem: "overview",
  }),
  methods: {
    setMenu(name) {
      this.$store.commit("SET_ACTIVE_MENU", name);
    },
    redirectToBilling() {
      this.$store.dispatch("getBillingPortal");
    },
    showIntercom() {
      this.$intercom.show();
    },
  },
};
</script>
