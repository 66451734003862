<template>
<v-container class="mt-15">
    <v-row>
         <v-toolbar-title>
                <v-btn text @click="pushToPortal()">
                    <v-icon> mdi-arrow-left </v-icon> aerwave.io
                </v-btn>
            </v-toolbar-title> 
    </v-row>
    <v-row align="center" justify="center">
        <v-card max-width="1000" elevation="5">
            <v-card-title> Free-December period is over...</v-card-title>
            <v-card-subtitle class="text-wrap"> To continue using our service,
                please provide your email address that you've used during free period,
                and choose which subscription package is most suitable for you. </v-card-subtitle>
            <v-form v-model="valid">
                <v-text-field class="ml-5 mr-5" v-model="email" :rules="emailRules" label="Email" outlined />
            </v-form>
            <v-row align="center" justify="center" v-if="subscriptions" class="ml-5 mr-5">
                <v-col sm="4" v-for="sub in subscriptions" :key="sub.subp_stripe_id">
                    <v-card class="mx-auto" max-width="344" outlined elevation="3" :color=" sub.subp_stripe_id == selected_sub ? `blue` : `transparent`" @click="selectSub(sub.subp_stripe_id)">
                        <v-list-item three-line>
                            <v-list-item-content>
                                <div class="text-h6 mb-4">
                                    {{sub.subp_name}}
                                </div>
                                <v-list-item-title class="text-h6 mb-1">
                                    {{sub.subp_price}}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{sub.subp_speed}} Mb/s</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="80"> <img src="../../assets/img/aerwave-circle.png" alt="logo" />></v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-progress-linear indeterminate color="primary" v-else />
            <v-snackbar v-model="snackbar.visible" :multi-line="snackbar.multiLine" :color="snackbar.color" :timeout="snackbar.timeout">
                {{ snackbar.text }}
            </v-snackbar>
            <v-card-subtitle> If you need help, do not hassinate to call us. </v-card-subtitle>

            <v-card-actions class="mb-5 mr-5">
                <v-flex class="text-right">
                    <v-btn :disabled="!valid || !selected_sub" :loading="processing" @click="proceedWithPayment">
                        <v-icon> mdi-credit-card </v-icon> Proceed with payment
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-row>
</v-container>
</template>

<script>
import {
    portal_api
} from '../../api/api.js'
import {
    sb_error,
    sb_succ
} from '../../api/helpers.js';

export default ({
    data() {
        return {
            subscriptions: null,
            processing: false,
            valid: false,
            email: "",
            selected_sub: null,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            snackbar: {
                multiLine: false,
                visible: false,
                text: ``,
                color: '',
                timeout: 3000,
            }
        }
    },
    methods: {
        getSubscriptions() {
            portal_api.get_subs(2).then(
                (res) => {
                    this.subscriptions = res.data;
                }
            ).catch(() => {
                this.subscriptions = null;
                this.$router.push('/')
            })
        },
        selectSub(sub) {
            this.selected_sub = sub;
        },
        proceedWithPayment() {
            this.processing = true

            portal_api.checkout_session(this.email, this.selected_sub).then(
                (res) => {
                    this.snackbar = sb_succ("Redirecting to Stripe...")
                    console.log(res)
                    location.href = res.data.checkout_url
                }
            ).catch((error) => (this.snackbar = sb_error(error.response.data.detail))).finally(() => (this.processing = false))

        },
        pushToPortal() {
            window.location.href = 'https://aerwave.io'
        }

    },
    mounted() {
        this.getSubscriptions()

    }
})
</script>
